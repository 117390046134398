import aws from 'aws-sdk';
import axios from 'axios';

import awsConfig from '../config/awsConfig.json';

aws.config.update({
  secretAccessKey: awsConfig.secretAccessKey,
  accessKeyId: awsConfig.accessKeyId,
});

const awsS3 = new aws.S3({
  signatureVersion: 'v4',
  region: awsConfig.region,
});

export function uploadFileToAws(folder, item) {
  console.log('function uploadFileToAWS', folder, item);
  let contentType = null;
  if (item.type) {
    contentType = item.type;
  } else if (item.file.type) {
    contentType = item.file.type;
  }
  const key = `${folder}/${item.filename}`;
  const params = {
    Bucket: awsConfig.bucket,
    Key: key,
    Expires: 100,
    ContentType: contentType,
  };
  const url = awsS3.getSignedUrl('putObject', params);
  return new Promise((resolve, reject) => {
    axios
      .put(url, item.file, {
        headers: {
          'Content-Type': contentType,
        },
      })
      .then((result) => {
        const bucketUrl = decodeURIComponent(result.request.responseURL).split(
          key,
        )[0];
        const imageFullPath = bucketUrl + key;
        console.log('imageFullPath of aws upload', imageFullPath);
        resolve(imageFullPath);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export default awsS3;
